import React from 'react'
import { HeadingTertiary } from '../components/atoms/Heading/HeadingTertiary'
import { ENGLISH_TITLE } from '../components/data/English'
import { SEO } from '../components/data/SEO'
import { LayoutNoSideNav } from '../components/organisms/Layout/LayoutNoSideNav'

const Page = () => {
  return (
    <LayoutNoSideNav
      heading="利用規約"
      headingEnglish={ENGLISH_TITLE.Policy}
      BreadcrumbData={[{ title: '利用規約', url: '/' }]}
    >
      <div className="pc:pt-12 pc:px-8 pb-12 bg-white">
        <HeadingTertiary label="本サイトのご利用について" />
        <ul className="text-md list-decimal pt-4 pl-6 pb-20 pc:pb-14 leading-normal">
          <li>
            当サイトの掲載情報の正確性については万全を期しておりますが、コンテンツ等の利用により発生した損害に関しては、一切の責任を負いません。
          </li>
          <li>
            当サイトは、予告なしに内容の一部変更または削除、利用条件、サイト構成、URL（アドレス）を変更する場合があります。
          </li>
        </ul>
        <HeadingTertiary label="著作権" />
        <p className="pt-4 pb-20 pc:pb-14 leading-normal">
          当サイトに記載されている内容の著作権は、CRGホールディングス株式会社及び情報提供者に帰属し、いかなる目的であれ無断での複製、転送、改編、修正、追加など一切の行為を禁止致します。
          当サイト及びそのコンテンツを使用できるのは、著作権法上「私的使用のための複製」及び「引用」などの場合に限られています。「私的使用のための複製」や「引用」といった範囲を超える場合には、当社の使用許諾が必要になります。
        </p>
        <HeadingTertiary label="リンク" />
        <ul className="text-md list-decimal pt-4 pl-6 pb-20 pc:pb-14 leading-normal">
          <li>
            当ウェブサイトのリンクは原則フリーですが、事前に crinfo@crgh.co.jp
            までご一報いただければ幸いです。
          </li>
          <li>
            以下に関連するリンクはお断りいたします。また、当社が一旦承諾した場合でも、削除をお願いすることがあります。
            <br />
            ・当社またはグループ会社、関連会社、それらの役員または社員を誹謗・中傷するサイト
            <br />
            ・公序良俗に反する違法なサイト、またはそうした内容を活動目的とし、当社の信用、品位が損なわれるサイト
          </li>
          <li>
            当サイトよりリンクされた他のホームページにおけるみなさまの個人情報などの保護について責任を負うものではありません。各リンク先のサイトの個人情報に関する定めなどをご確認されることをお勧めいたします。
          </li>
        </ul>
        <HeadingTertiary label="準拠法、裁判管轄" />
        <ul className="text-md list-decimal pt-4 pl-6 pb-20 pc:pb-14 leading-normal">
          <li>
            本サイトのご利用ならびに「本サイトのご利用にあたって」の解釈および適用は、別段の定めがない限り、日本法に準拠します。
          </li>
          <li>
            本サイトに関するすべての紛争は、別段の定めがない限り、東京地方裁判所を第一審の管轄裁判所とします。
          </li>
        </ul>
        <HeadingTertiary label="お問い合わせ" />
        <p className="pt-4 pb-20 pc:pb-14 leading-normal">
          当ウェブサイト・当社についてご意見・ご質問などがある場合は、crinfo@crgh.co.jp
          までご連絡ください。
        </p>
        <HeadingTertiary label="対応ブラウザ" />
        <p className="pt-4 leading-normal">
          当ウェブサイトを閲覧する際には、Microsoft
          Edge最新版、Chrome最新版、Firefox最新版のブラウザの使用を推奨いたします。これら推奨ブラウザ以外でご覧いただく場合、画面の一部が正しく表示されないことがあります。
        </p>
        {/* ------------------------------------------------ */}
        {/* ここまでメインエリア */}
        {/* ------------------------------------------------ */}
      </div>
    </LayoutNoSideNav>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEO title="利用規約" url="/policy" />
